import { Chips } from '@swe/shared/ui-kit/components/chips';
import { CheckboxGroup } from '@swe/shared/ui-kit/components/form/checkbox-group';
import { ChevronDownIcon, ChevronUpIcon } from '@swe/shared/ui-kit/components/icon';
import { Popover, PopoverContext } from '@swe/shared/ui-kit/components/popover';
import { POPOVER_HIDE_PADDING } from '@swe/shared/ui-kit/components/popover/config';
import cl from 'clsx';

import { useCallback, useEffect, useRef, useState } from 'react';

import styles from 'domains/catalog/components/selected-filters/styles.module.scss';
import { getFilterValue } from 'domains/catalog/helpers';
import { mapFilterOptions, ProductFilterAny, ProductFilterType } from 'entities/product/filter';

type ChipsProps = {
  filter: ProductFilterAny;
  idx: number;
  resetField: (name: string) => void;
  setValue: (name: any, value: any) => void;
};

const ChipsSelected = ({ filter, idx, resetField, setValue }: ChipsProps) => {
  const popoverRef = useRef<PopoverContext>(null);

  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const onToggle = useCallback(() => {
    if (popoverRef.current?.isOpen) {
      popoverRef.current?.close();
    } else {
      popoverRef.current?.open();
    }
    setIsOpenPopover(!popoverRef.current?.isOpen);
  }, []);

  const refContent = useRef<HTMLDivElement>(null);
  const refChips = useRef<HTMLDivElement>(null);

  const closePopover = useCallback(() => {
    popoverRef.current?.close();
    setIsOpenPopover(false);
  }, []);

  useEffect(() => {
    function handleClickOutside(e: Event) {
      if (
        e.target &&
        !refContent.current?.contains(e.target as HTMLElement) &&
        !refChips.current?.contains(e.target as HTMLElement)
      ) {
        closePopover();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [closePopover]);

  const chips = (hasArrow = false) => {
    return (
      <Chips
        ref={refChips}
        key={`${filter.key}_${idx}`}
        className={cl(hasArrow && styles.chipsActive)}
        rightIcon={hasArrow ? (isOpenPopover ? ChevronUpIcon : ChevronDownIcon) : undefined}
        onClose={() => resetField(filter.key)}
        onClick={onToggle}
      >
        {filter.uiLabel}: {getFilterValue(filter)}
      </Chips>
    );
  };

  if (filter.type === ProductFilterType.MULTIPLE) {
    return (
      <Popover
        ref={popoverRef}
        placement="bottom-start"
        trigger="click"
        hidePadding={POPOVER_HIDE_PADDING}
        offset="xxxs"
        strategy="fixed"
        disableFlip
        onForceClose={closePopover}
        tabbable
        content={
          <div
            ref={refContent}
            className={styles.dropDown}
          >
            <CheckboxGroup
              name="selectedFilters"
              key={filter.key}
              options={mapFilterOptions(filter.options)}
              searchPlaceholder={`Search ${filter.uiLabel}`}
              expandable={false}
              expanded
              showSelectedTags
              value={filter.value}
              variant="check"
              maxListHeight="260px"
              onChange={(nextValue) => setValue(filter.key, nextValue)}
            />
          </div>
        }
      >
        {chips(true)}
      </Popover>
    );
  }
  return chips();
};

export { ChipsSelected };
