import { useMounted } from '@swe/shared/hooks/use-mounted';
import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { useBreakpoint } from '@swe/shared/tools/media';
import Button from '@swe/shared/ui-kit/components/button';
import Container from '@swe/shared/ui-kit/components/container';
import { ChevronUpIcon } from '@swe/shared/ui-kit/components/icon';
import { Portal } from '@swe/shared/ui-kit/components/portal';

import { ScrollPosition, useNearestScrollable } from '@swe/shared/ui-kit/components/scrollable';
import cx from 'clsx';
import { useCallback } from 'react';

import styles from './styles.module.scss';

const ScrollToTop = () => {
  const [isVisible, show, hide] = useToggleable();
  const { scroll, subscribe, unsubscribe } = useNearestScrollable();
  const { mobile } = useBreakpoint();

  useMounted(() => {
    const listener = ({ scrollTop }: ScrollPosition) => {
      if (scrollTop >= window.innerHeight * 2) {
        show();
      } else {
        hide();
      }
    };

    subscribe('scrollPosition', listener);

    return () => {
      unsubscribe('scrollPosition', listener);
    };
  });

  const handleClick = useCallback(() => {
    scroll({ top: 0, behavior: 'smooth' });
  }, [scroll]);

  return (
    <Portal name="fixedFooterTop">
      <div className={cx(styles.root, !isVisible && styles._hidden)}>
        <Container className={styles.container}>
          <Button
            className={styles.control}
            ariaLabel="Scroll to top button"
            icon={ChevronUpIcon}
            color="light"
            onClick={handleClick}
          >
            {!mobile ? 'Back to top' : ''}
          </Button>
        </Container>
      </div>
    </Portal>
  );
};

export { ScrollToTop };
