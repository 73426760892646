import CheckboxGroup from '@swe/shared/ui-kit/components/form/checkbox-group';
import RadioGroup from '@swe/shared/ui-kit/components/form/radio-group';
import FRange from '@swe/shared/ui-kit/components/form/range';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { useCallback } from 'react';

import { ExpandableFilter } from 'domains/catalog/components/expandable-filter';
import { prepareFilterHeadingProps } from 'domains/catalog/helpers';
import { mapFilterOptions, ProductFilterAny, ProductFilterType, ProductFilterUnit } from 'entities/product/filter';

type SidebarFiltersProps = ComponentHasClassName & {
  filters: ProductFilterAny[];
  filterStateHandler: (filter: { name: string; defaultExpanded: boolean }) => {
    onToggle: (v?: boolean) => void;
    expanded: boolean;
  };
  setFilter: (key: string, value: any) => void;
  clearFilter: (key: string) => void;
};

const SidebarFilters = ({ filters, className, filterStateHandler, clearFilter, setFilter }: SidebarFiltersProps) => {
  const tokens = useTheme();
  const renderFilter = useCallback(
    (filter: ProductFilterAny, index: number) => {
      const name = `sidebar.${filter.key}`;
      const defaultExpanded = filter.expandedByDefault;
      const baseProps = {
        key: filter.key,
        defaultExpanded,
        heading: prepareFilterHeadingProps(filter, clearFilter),
        name,
        ...filterStateHandler({
          name,
          defaultExpanded,
        }),
        idPrefix: 'sidebar-',
      };
      switch (filter.type) {
        case ProductFilterType.RANGE:
          return (
            <ExpandableFilter
              {...baseProps}
              value={filter.value}
              onChange={(val) => setFilter(filter.key, val)}
              noTopPadding={index === 0}
              as={FRange}
              label={false}
              min={filter.range.min}
              max={filter.range.max}
              format={
                (!filter.range.unitAbbr
                  ? 'number'
                  : filter.range.unitAbbr === ProductFilterUnit.DOLLAR
                    ? 'dollar'
                    : 'percent') as 'dollar' | 'number'
              }
            />
          );
        case ProductFilterType.MULTIPLE:
          return (
            <ExpandableFilter
              {...baseProps}
              value={filter.value}
              onChange={(val) => setFilter(filter.key, val)}
              noTopPadding={index === 0}
              as={CheckboxGroup}
              variant={filter.presentation === 'Tag' ? 'tag' : 'check'}
              options={mapFilterOptions(filter.options)}
              searchPlaceholder={`Search ${filter.uiLabel}`}
              maxListHeight={tokens.filter.contentMaxHeight}
            />
          );
        case ProductFilterType.SINGLE:
          return (
            <ExpandableFilter
              {...baseProps}
              value={filter.value}
              onChange={(val) => setFilter(filter.key, val)}
              noTopPadding={index === 0}
              as={RadioGroup}
              variant="tag-filterable"
              options={mapFilterOptions(filter.options)}
              searchPlaceholder={`Search ${filter.uiLabel}`}
            />
          );
        default:
          return null;
      }
    },
    [clearFilter, filterStateHandler, setFilter, tokens.filter.contentMaxHeight],
  );

  return <div className={className}>{filters.map(renderFilter)}</div>;
};

export type { SidebarFiltersProps };
export { SidebarFilters };
export default SidebarFilters;
