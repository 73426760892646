import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import { ExpandableSectionProps, Section, SectionProps } from '@swe/shared/ui-kit/components/section';
import { PatternThemeProvider } from '@swe/shared/ui-kit/theme/provider';
import { FunctionComponent } from 'react';

import styles from './styles.module.scss';

type SingleFilterProps<PT> = {
  as: FunctionComponent<PT>;
} & Pick<SectionProps, 'defaultExpanded' | 'heading' | 'noTopPadding' | 'onToggle' | 'expanded'> &
  PT;

const ExpandableFilter = <PT extends FormControl<any>>({
  as: Field,
  heading,
  defaultExpanded,
  noTopPadding,
  onToggle,
  expanded,
  ...fieldProps
}: SingleFilterProps<PT>) => {
  const sectionProps = {
    expandable: !defaultExpanded,
    onToggle,
    heading,
    noTopPadding,
    expanded,
    headingLevel: 3,
  } as ExpandableSectionProps;
  return (
    <PatternThemeProvider
      name="filter"
      className={styles.root}
    >
      <Section {...sectionProps}>
        <Field {...(fieldProps as any as PT)} />
      </Section>
    </PatternThemeProvider>
  );
};

export { ExpandableFilter };
export default ExpandableFilter;
export type { SingleFilterProps };
