import { PageHeaderProps } from 'common/components/page-header';
import { CatalogProps } from 'domains/catalog/containers/catalog';
import useCompilations from 'domains/catalog/use-cases/use-compilations';
import useSearch from 'domains/catalog/use-cases/use-search';
import { CompilationType } from 'entities/common/compilation';

const useEntryPoint = () => {
  const { isCompilationPage, ...compilationData } = useCompilations();
  const searchData = useSearch();

  return { ...(isCompilationPage ? compilationData : searchData), isCompilationPage } as {
    compilationType?: CompilationType;
    pageHeader?: PageHeaderProps;
    catalogFilter?: CatalogProps['outerFilters'];
    isLoading?: boolean;
    isCompilationPage: boolean;
  };
};

export { useEntryPoint };
export default useEntryPoint;
