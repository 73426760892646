import cn from 'clsx';
import { ReactNode } from 'react';

import { ComponentHasChildren, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type SidebarLayoutProps = ComponentHasChildren &
  ComponentHasClassName & {
    sidebar?: ReactNode;
    vAlign?: 'center';
  };

const SidebarLayout = ({ children, sidebar, className, vAlign }: SidebarLayoutProps) => {
  if (!sidebar) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div className={cn(styles.root, className, vAlign === 'center' && { [styles[`_vAlign_${vAlign}`]]: !!vAlign })}>
      <div className={styles.sidebar}>{sidebar}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export type { SidebarLayoutProps };
export { SidebarLayout };
