import { Hero } from '@swe/shared/ui-kit/components/hero';
import { NoProductsIllustration } from '@swe/shared/ui-kit/components/illustration';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { useInactiveDiscountConfig } from './config';

import { InactivityReason } from 'entities/discount/discount';

type InactiveDiscountProps = ComponentHasClassName & {
  reason: InactivityReason;
};

const InactiveDiscount = ({ reason }: InactiveDiscountProps) => {
  const config = useInactiveDiscountConfig({ reason });
  if (!config) return null;

  const actions = config.actions.map((item, index, items) => ({
    ...item,
    block: false,
    color: index === items.length - 1 ? undefined : ('light' as const),
  }));

  return (
    <Hero
      illustration={<NoProductsIllustration />}
      title={config.title}
      content={config.description}
      actions={actions}
      border={false}
    />
  );
};

export { InactiveDiscount };
