import Grid from '@swe/shared/ui-kit/components/grid';
import { ReactNode } from 'react';

type GridItem = { id: number | string } | ReactNode;

type RenderGrid = <Item extends GridItem>(
  items: Item[],
  render?: (item: Item, index: number) => ReactNode | any,
) => ReactNode;

const renderGrid: RenderGrid = (items, render) => (
  <Grid.Row spacing={{ xs: 'xxs', sm: 'sm' }}>
    {items.map((item, index) => {
      const itemId = item && typeof item === 'object' && 'id' in item ? item.id : index;
      return (
        <Grid.Cell
          key={`cell_${itemId}_${index}`}
          cols={{
            xs: 6,
            md: 4,
            lg: 3,
            xl: 4,
            xxl: 3,
          }}
        >
          {render?.(item, index) ?? item}
        </Grid.Cell>
      );
    })}
  </Grid.Row>
);

export { renderGrid };
