import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';

const useSearch = () => {
  const {
    catalogQuery: { searchTerm },
    clearSearch,
  } = useCatalogQuery();

  return {
    pageHeader: {
      ...(searchTerm ? { title: `"${searchTerm}"`, onClickClose: clearSearch } : {}),
    },
  };
};

export { useSearch };
export default useSearch;
