import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';

import { Discount, InactivityReason } from 'entities/discount/discount';
import { StoreSaleType } from 'entities/shop/sale-type';

type Params = {
  promoId: number;
  saleType: StoreSaleType;
  platformOs: PlatformOs;
};

type Data = {
  promo?: Discount;
  reason: InactivityReason;
};

const NAME = '/Products/GetPromotion';
const GetPromoBannerEndpoint = createEndpoint<Params, Data>(NAME);

export default GetPromoBannerEndpoint;
export { GetPromoBannerEndpoint };
