import { omit } from '@swe/shared/utils/object';

import { PageHeaderProps } from 'common/components/page-header';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { DiscountInfo } from 'domains/product/components/discount-card/components/discount-info';
import { GetCarouselListEndpoint } from 'endpoints/product/get-carousel-list';
import { GetPromoBannerEndpoint } from 'endpoints/promotion/get-promotion';
import { CompilationType } from 'entities/common/compilation';

type CompilationData = Omit<PageHeaderProps, 'title'> & {
  name: string;
  filter: Record<string, unknown>;
};

const useCompilations = () => {
  const { saleType } = useSaleType();
  const { catalogQuery } = useCatalogQuery();
  const { platformOs } = usePlatformOs();

  const isCompilationPage = catalogQuery.page === 'compilation';
  let compilationType: CompilationType | null = null;
  let compilationId: EntityID<string> | null = null;
  if (isCompilationPage) {
    compilationType = catalogQuery.compilationType;
    compilationId = catalogQuery.compilationId;
  }

  const { data: carousels = [], isLoading: isLoadingCarousels } = GetCarouselListEndpoint.useRequest(
    compilationType === CompilationType.CAROUSEL && isCompilationPage ? { saleType, platformOs } : null,
  );

  const { data: promoBanner, isLoading: isLoadingDiscounts } = GetPromoBannerEndpoint.useRequest(
    compilationType === CompilationType.DISCOUNT && isCompilationPage
      ? {
          saleType,
          platformOs,
          promoId: Number(compilationId),
        }
      : null,
    { headers: { SaleType: saleType as string } },
  );

  let data: CompilationData | undefined;

  switch (compilationType) {
    case CompilationType.CAROUSEL: {
      data = carousels.find(({ id }) => String(id) === compilationId);
      break;
    }

    /* 6 case  Promo page */
    /* check this place in Cart page on Deal */
    case CompilationType.DISCOUNT: {
      if (promoBanner?.promo) {
        data = {
          ...promoBanner.promo,
          caption: (
            <DiscountInfo
              fulfilmentTypes={promoBanner.promo.fulfilmentTypes}
              schedule={promoBanner.promo.scheduleDisplayText}
            />
          ),
        };
      }
      if (promoBanner?.reason) {
        data = {
          ...data,
          inactivityReason: promoBanner.reason,
        } as CompilationData;
      }
      break;
    }
    default: {
      data = undefined;
    }
  }

  const isLoading = isLoadingCarousels || isLoadingDiscounts;

  const notFound = !data && isCompilationPage && !isLoading;

  return {
    compilationType,
    catalogFilter: data?.filter,
    pageHeader: notFound
      ? null
      : {
          title: data?.name,
          ...omit(data, ['filter', 'name']),
        },
    isCompilationPage,
    isLoading,
  };
};

export { useCompilations };
export default useCompilations;
