import { Button } from '@swe/shared/ui-kit/components/button';
import { Container } from '@swe/shared/ui-kit/components/container';
import CheckboxGroup from '@swe/shared/ui-kit/components/form/checkbox-group';
import RadioGroup from '@swe/shared/ui-kit/components/form/radio-group';
import FRange from '@swe/shared/ui-kit/components/form/range';
import { CloseIcon, ChevronRightIcon, ChevronLeftIcon } from '@swe/shared/ui-kit/components/icon';

import { ModalMobile } from '@swe/shared/ui-kit/components/modal/mobile';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';

import { Stack } from '@swe/shared/ui-kit/components/stack';

import { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import { getFilterValue, prepareFilterHeadingProps } from 'domains/catalog/helpers';
import {
  mapFilterOptions,
  ProductFilterAny,
  ProductFiltersConfigMap,
  ProductFilterType,
  ProductFilterUnit,
} from 'entities/product/filter';

type MobileFiltersProps = {
  total: number;
  isLoading: boolean;
  filters: ProductFilterAny[];
  filtersDescriptor: ProductFiltersConfigMap;
  isOpened: boolean;
  onClose: () => void;
  onApply: () => Promise<void>;
  onClearAll: () => void;
  onClearField(key: string): void;
  onResetField(key: string): void;
  onSetField(key: string, value: any): void;
  areFiltersTouched: boolean;
};

const MobileFilters = ({
  isOpened,
  onClose,
  isLoading,
  onApply,
  total = 0,
  filters,
  onClearAll,
  onClearField,
  onSetField,
  onResetField,
  areFiltersTouched,
  filtersDescriptor,
}: MobileFiltersProps) => {
  const [innerModalDataKey, setInnerModalDataKey] = useState<ProductFilterAny['key']>();

  const openFilter = useCallback((data: ProductFilterAny) => {
    setInnerModalDataKey(data.key);
  }, []);

  const renderFilter = useCallback(
    (filter?: ProductFilterAny) => {
      if (!filter) {
        return;
      }
      const baseProps = {
        name: `sidebar.${filter.key}`,
      };

      switch (filter.type) {
        case ProductFilterType.RANGE:
          return (
            <FRange
              {...baseProps}
              value={filter.value}
              onChange={(val) => onSetField(filter.key, val)}
              key={filter.key}
              label={false}
              min={filter.range.min}
              max={filter.range.max}
              format={
                !filter.range.unitAbbr
                  ? 'number'
                  : filter.range.unitAbbr === ProductFilterUnit.DOLLAR
                    ? 'dollar'
                    : 'percent'
              }
            />
          );
        case ProductFilterType.MULTIPLE:
          return (
            <CheckboxGroup
              {...baseProps}
              value={filter.value}
              onChange={(val) => onSetField(filter.key, val)}
              key={filter.key}
              variant={filter.presentation === 'Tag' ? 'tag' : 'check'}
              options={mapFilterOptions(filter.options)}
              searchPlaceholder={`Search ${filter.uiLabel}`}
              expandable={false}
              expanded
              maxListHeight="calc(100vh - 256px)"
              showSelectedTags={filter.presentation !== 'Tag'}
            />
          );
        case ProductFilterType.SINGLE:
          return (
            <RadioGroup
              {...baseProps}
              value={filter.value}
              onChange={(val) => onSetField(filter.key, val)}
              key={filter.key}
              variant="tag-filterable"
              options={mapFilterOptions(filter.options)}
              searchPlaceholder={`Search ${filter.uiLabel}`}
              expandable={false}
            />
          );
        default:
          return null;
      }
    },
    [onSetField],
  );

  const renderFilterRow = useCallback(
    (filter: ProductFilterAny) => {
      if (filter.expandedByDefault) {
        return (
          <div
            className={styles.item}
            key={filter.key}
          >
            {filter.uiLabel && (
              <SectionHeading
                {...prepareFilterHeadingProps(filter, onClearField)}
                size="sm"
                noPadding
              />
            )}
            {renderFilter(filter)}
          </div>
        );
      }

      return (
        <SectionHeading
          key={filter.key}
          size="sm"
          onClick={() => openFilter(filter)}
          trailIcon={ChevronRightIcon}
          subHeading={filter.isTouched ? getFilterValue(filter, true) : undefined}
          button={
            filter.isTouched
              ? {
                  icon: CloseIcon,
                  variant: 'link',
                  children: 'Clear',
                  color: 'danger',
                  onClick: (ev: any) => {
                    ev.stopPropagation();
                    onClearField(filter.key);
                  },
                }
              : undefined
          }
        >
          {filter.uiLabel}
        </SectionHeading>
      );
    },
    [onClearField, openFilter, renderFilter],
  );

  const submit = useCallback(async () => {
    await onApply();
    onClose();
  }, [onApply, onClose]);

  const applyFilter = useCallback(() => {
    setInnerModalDataKey(undefined);
  }, []);

  const closeFilter = useCallback(() => {
    if (innerModalDataKey) {
      onResetField(innerModalDataKey);
    }
    setInnerModalDataKey(undefined);
  }, [innerModalDataKey, onResetField]);

  const innerModalData = innerModalDataKey ? filtersDescriptor[innerModalDataKey] : undefined;

  return (
    <>
      <ModalMobile
        visible={isOpened}
        variant="fullscreen"
        width="full"
        maxWidth="sm"
        ariaLabel="Filters"
        sectionHeading={{
          children: 'Filter',
          bullet: {
            icon: ChevronLeftIcon,
            onClick: onClose,
          },
          ...(areFiltersTouched
            ? {
                button: {
                  icon: CloseIcon,
                  color: 'primary',
                  variant: 'link',
                  children: 'Clear all',
                  onClick: onClearAll,
                },
              }
            : {}),
        }}
        isStacked={false}
        footer={
          <Container maxWidth="xs">
            <Button
              pending={isLoading}
              onClick={submit}
              block
              name="show-products"
            >
              Show {total} products
            </Button>
          </Container>
        }
      >
        <Stack
          divider
          spacing="none"
        >
          {filters.map(renderFilterRow)}
        </Stack>
      </ModalMobile>
      <ModalMobile
        visible={!!innerModalData}
        variant="fullscreen"
        ariaLabel="Subfilters"
        width="full"
        maxWidth="sm"
        heading={innerModalData && innerModalData.uiLabel}
        sectionHeading={{
          children: innerModalData?.uiLabel,
          bullet: { icon: ChevronLeftIcon, onClick: closeFilter },
          ...(innerModalData?.isTouched
            ? {
                button: {
                  icon: CloseIcon,
                  variant: 'link',
                  color: 'primary',
                  children: 'Clear',
                  onClick: (e: any) => {
                    e.stopPropagation();
                    onClearField(innerModalData.key);
                  },
                },
              }
            : {}),
        }}
        footer={
          <Container maxWidth="xs">
            <Button
              pending={isLoading}
              block
              onClick={applyFilter}
              disabled={total <= 0}
              name="apply"
            >
              {total > 0 ? `Apply – ${total} products matched` : '0 products matched'}
            </Button>
          </Container>
        }
      >
        {renderFilter(innerModalData)}
      </ModalMobile>
    </>
  );
};

export { MobileFilters };
export default MobileFilters;
