import { usePrevious } from '@swe/shared/hooks';
import { useDeepCompareEffect } from '@swe/shared/hooks/use-deep-compare';
import { isEqual, omit } from '@swe/shared/utils/object';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { CatalogFiltersValues } from 'domains/catalog/constants';

import { getFilterValue } from 'domains/catalog/helpers';
import useCatalogQuery, { clearEmptyFilters } from 'domains/catalog/use-cases/use-catalog-query';
import { GetListEndpoint } from 'endpoints/product/get-list';
import { FilterKey } from 'entities/common/catalog';
import { CompilationType } from 'entities/common/compilation';
import { ProductFilters } from 'entities/product/filter';

const useCatalog = (outerFilters: Record<string, unknown> = {}) => {
  const { pushEvent } = useAnalytics();
  const { saleType } = useSaleType();
  const prevSaleType = usePrevious(saleType);
  const { platformOs } = usePlatformOs();

  const [fetchedFilters, setFetchedFilters] = useState<ProductFilters>([]);

  const [tempFilters, setTempFilters] = useState<CatalogFiltersValues | undefined>(undefined);
  const catalogQueryResult = useCatalogQuery(fetchedFilters, tempFilters);
  const { catalogQuery, productFilters, toCatalog, filtersDescriptor, getCategoryName, setField, clearPagination } =
    catalogQueryResult;
  const { pagination, filters: catalogQueryFilters, searchTerm, categoryId, sortingMethod } = catalogQuery;
  const filters = tempFilters ?? catalogQueryFilters;
  const isProductUrl = catalogQuery.page === 'product';

  useEffect(() => {
    if (prevSaleType && saleType !== prevSaleType) {
      clearPagination();
    }
  }, [saleType, prevSaleType, clearPagination]);

  const { data, isLoading, isValidating } = GetListEndpoint.useRequest(
    !isProductUrl
      ? {
          filters: { ...outerFilters, ...filters },
          ...pagination,
          sortingMethodId: sortingMethod,
          searchTerm,
          saleType,
          platformOs,
        }
      : null,
    {},
    { keepPreviousData: true },
  );

  const {
    sortingMethods,
    list: products,
    total,
  } = useMemo(
    () =>
      data ?? {
        sortingMethods: [],
        list: [],
        total: 0,
      },
    [data],
  );

  useDeepCompareEffect(() => {
    setFetchedFilters(data?.filters ?? []);
  }, [data?.filters]);

  const snapTempFilters = useCallback(() => {
    setTempFilters({ ...catalogQuery.filters });
  }, [catalogQuery.filters]);
  const releaseTempFilters = useCallback(() => setTempFilters(undefined), []);
  const setTempField = useCallback(
    (key: string, value: any) =>
      setTempFilters((filters) =>
        clearEmptyFilters(
          {
            ...filters,
            [key]: value,
          },
          filtersDescriptor,
        ),
      ),
    [filtersDescriptor],
  );
  const clearTempField = useCallback((key: string) => {
    setTempFilters((filters) => omit(filters, [key]));
  }, []);
  const resetTempField = useCallback(
    (key: string) => {
      setTempFilters((filters) => ({
        ...filters,
        [key]: catalogQuery.filters[key],
      }));
    },
    [catalogQuery.filters],
  );
  const clearTempFilters = useCallback(() => {
    setTempFilters({});
  }, []);
  const applyTempFilters = useCallback(async () => {
    const subPath =
      catalogQuery.page === 'category' &&
      (tempFilters?.[FilterKey.Category]?.length > 1 || !tempFilters?.[FilterKey.Category])
        ? null
        : undefined;
    await toCatalog({ filters: tempFilters, subPath }, true);
  }, [catalogQuery.page, tempFilters, toCatalog]);

  useDeepCompareEffect(() => {
    if (fetchedFilters.length === 0) {
      return;
    }
    pushEvent(AEventType.PAGE_VIEW, {
      category_name: catalogQuery.page === 'category' ? getCategoryName(catalogQuery.categoryId) : undefined,
      sort: sortingMethods?.find((sm) => sm.id === catalogQuery.sortingMethod)?.name,
      filters: Object.fromEntries(
        productFilters
          .filter(
            (filter) =>
              filter.isTouched && (catalogQuery.page === 'category' ? filter.key !== FilterKey.Category : true),
          )
          .map((filter) => [filter.key, getFilterValue(filter, true, false, '|')]),
      ),
    });
  }, [fetchedFilters]);

  const toolbarFilters = useMemo(() => {
    return categoryId === -1 ? productFilters : productFilters.filter((f) => f.key !== FilterKey.Category);
  }, [categoryId, productFilters]);

  const quickFilterValue = filtersDescriptor?.[FilterKey.QuickFilter]?.value;

  const isDeals = useMemo(() => {
    if (catalogQuery.page === 'compilation' && catalogQuery.compilationType === CompilationType.DISCOUNT) {
      return true;
    }
    if ((Array.isArray(quickFilterValue) ? quickFilterValue : [quickFilterValue]).includes(2)) {
      return true;
    }
  }, [catalogQuery, quickFilterValue]);

  const setFilter = useCallback(
    (fieldName: string, value: any) => {
      const matchedFilter = productFilters.find(({ key }) => key === fieldName);
      if (!matchedFilter || !isEqual(value, matchedFilter.value)) {
        setField(fieldName, value);
      }
    },
    [setField, productFilters],
  );

  return {
    sortingMethods,
    ...catalogQueryResult,
    toolbarFilters,
    setTempField,
    clearTempField,
    resetTempField,
    clearTempFilters,
    applyTempFilters,
    snapTempFilters,
    releaseTempFilters,
    isDeals,
    products,
    isLoading,
    isValidating,
    pagination: {
      ...pagination,
      total,
    },
    setFilter,
  };
};

export { useCatalog };
