import { ButtonProps } from '@swe/shared/ui-kit/components/button';

import { useCurrentUser } from 'common/providers/user';

import { Routes } from 'common/router/constants';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { InactivityReason } from 'entities/discount/discount';

import { ProductSaleType } from 'entities/product/product';

const OPPOSITE_SALE_TYPE = {
  [ProductSaleType.MEDICAL]: ProductSaleType.RECREATIONAL,
  [ProductSaleType.RECREATIONAL]: ProductSaleType.MEDICAL,
} as const;

const useInactiveDiscountActions = () => {
  const { saleType, setSaleType } = useSaleType();
  const { toSignIn } = useCurrentUser();

  return {
    TOGGLE_SALE_TYPE: {
      children: 'Switch menus',
      ariaLabel: 'switchMenus',
      onClick: () => {
        setSaleType?.(OPPOSITE_SALE_TYPE[saleType]);
      },
    },
    SHOP_DEALS: {
      children: 'Shop Deals',
      ariaLabel: 'goToDeals',
      href: Routes.Discounts,
    },
    SIGN_IN: {
      children: 'Sign In',
      ariaLabel: 'signIn',
      onClick: () => {
        toSignIn();
      },
    },
  };
};

const SALE_TYPE_TITLE = {
  [ProductSaleType.MEDICAL]: 'Medical',
  [ProductSaleType.RECREATIONAL]: 'Adult use',
};

type UseInactiveDiscountConfig = (params: { reason: InactivityReason }) => {
  title: React.ReactNode;
  description: React.ReactNode;
  actions: ButtonProps[];
} | null;

const useInactiveDiscountConfig: UseInactiveDiscountConfig = ({ reason }) => {
  const { saleType } = useSaleType();
  const { TOGGLE_SALE_TYPE, SHOP_DEALS, SIGN_IN } = useInactiveDiscountActions();

  return {
    [InactivityReason.NOT_ACTIVE]: {
      title: 'This deal has expired!',
      description: 'See our current deals for more great savings.',
      actions: [SHOP_DEALS],
    },
    [InactivityReason.OUT_OF_STOCK]: {
      title: 'These products are out of stock!',
      description:
        'The products from this deal are currently out of stock. Please check back later or shop other deals',
      actions: [SHOP_DEALS],
    },
    [InactivityReason.NOT_AVAILABLE_FOR_USER]: {
      title: 'This deal is only available for eligible customers!',
      description: 'But there are other deals to shop for more great savings!',
      actions: [SHOP_DEALS],
    },
    [InactivityReason.NOT_AUTHORIZED_USER]: {
      title: 'Please sign in to view this deal',
      description: 'This deal is only available for eligible users. Please sign in to see if you qualify!',
      actions: [SHOP_DEALS, SIGN_IN],
    },
    [InactivityReason.BAD_SALE_TYPE]: {
      title: `This deal is exclusive to the ${SALE_TYPE_TITLE[OPPOSITE_SALE_TYPE[saleType!]]} menu`,
      description: 'Please switch menus to see qualifying deals!',
      actions: [SHOP_DEALS, TOGGLE_SALE_TYPE],
    },
    [InactivityReason.ANOTHER_TIME]: null,
  }[reason];
};

export { useInactiveDiscountConfig };
