import { useBreakpoint } from '@swe/shared/tools/media';
import { ChevronDownIcon, ChevronUpIcon } from '@swe/shared/ui-kit/components/icon';
import { List } from '@swe/shared/ui-kit/components/list';
import { Popover } from '@swe/shared/ui-kit/components/popover';
import { getShiftValue, POPOVER_HIDE_PADDING } from '@swe/shared/ui-kit/components/popover/config';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';

import { useCallback, useMemo } from 'react';

import styles from './styles.module.scss';

import { ProductFilterOption } from 'entities/product/filter';

type CategoryMenuProps = {
  categories: ProductFilterOption[];
  categoryId: number;
  onChange?(categoryId: EntityID<number>): void;
};

const CategoryMenu = ({ onChange, categoryId, categories }: CategoryMenuProps) => {
  const onPick = useCallback(
    (categoryId: EntityID<number>) => {
      onChange?.(categoryId);
    },
    [onChange],
  );

  const options = useMemo(() => {
    return [
      { label: 'All Products', value: -1, onPick },
      ...categories.map(({ id, name }) => ({
        label: name,
        value: Number(id),
        onPick,
      })),
    ].map((item) => ({
      ...item,
      isActive: item.value === categoryId,
    }));
  }, [categories, categoryId, onPick]);

  const label = options.find(({ value }) => categoryId === value)?.label;
  const { mobile } = useBreakpoint();

  return (
    <Popover
      content={
        <List
          maxHeight={`min(350px, calc(100vh - ${getShiftValue(mobile) * 2}px))`}
          className={styles.list}
          items={options}
          withFocusTrap
        />
      }
      placement="bottom-start"
      trigger="click"
      shiftCrossAxis
      hidePadding={POPOVER_HIDE_PADDING}
    >
      {({ isOpen }) => (
        <div tabIndex={0}>
          <SectionHeading
            size="xl"
            level={1}
            trailIcon={isOpen ? ChevronUpIcon : ChevronDownIcon}
          >
            {label}
          </SectionHeading>
        </div>
      )}
    </Popover>
  );
};

export { CategoryMenu };
