import { usePersistState } from '@swe/shared/providers/persist-state';
import { useIntl } from '@swe/shared/tools/intl';
import { useBreakpoint } from '@swe/shared/tools/media';
import Box from '@swe/shared/ui-kit/components/box';

import Button from '@swe/shared/ui-kit/components/button';

import { DiscountLabelIcon } from '@swe/shared/ui-kit/components/icon';
import { Pagination } from '@swe/shared/ui-kit/components/pagination';
import { useNearestScrollable } from '@swe/shared/ui-kit/components/scrollable';
import { SidebarLayout } from '@swe/shared/ui-kit/layouts/sidebar';
import cx from 'clsx';

import { useCallback, useMemo, useState } from 'react';

import { renderGrid } from './helpers';

import styles from './styles.module.scss';

import { CatalogFormEmptyState } from 'common/components/catalog-empty-state';
import { PageHeader, PageHeaderProps } from 'common/components/page-header';
import { PathBasedBreadcrumbs } from 'common/components/path-based-breadcrumbs';
import { ProductCardSkeleton } from 'common/components/product-card/vertical/skeleton';
import { ScrollToTop } from 'common/components/scroll-to-top';
import { InactiveDiscount } from 'common/containers/inactive-discount';
import { ProductCardConnected } from 'common/containers/product-card';

import { getBreadcrumbTitle, Routes } from 'common/router/constants';
import MobileFilters from 'domains/catalog/components/mobile-filters';
import { SidebarFilters } from 'domains/catalog/components/sidebar-filters';
import { Toolbar } from 'domains/catalog/components/toolbar';
import { CategoryMenu } from 'domains/catalog/containers/category-menu';
import { useCatalog } from 'domains/catalog/use-cases/use-catalog';
import { useDealsModal } from 'domains/product/containers/deals-modal';
import { FilterKey } from 'entities/common/catalog';
import { CompilationType } from 'entities/common/compilation';
import { InactivityReason } from 'entities/discount/discount';
import { ProductFilterTag } from 'entities/product/filter';

const MAX_NOT_LAZY_ITEMS = 8;

export type CatalogProps = {
  pageHeader?: PageHeaderProps;
  outerFilters: any;
  isCompilationPage: boolean;
  compilationType?: CompilationType;
  compilationTitle?: string;
};

const Catalog = ({ pageHeader, outerFilters, isCompilationPage, compilationType, compilationTitle }: CatalogProps) => {
  const { desktop, lessThan } = useBreakpoint();
  const { intl } = useIntl();
  const { scroll } = useNearestScrollable();
  const dealsModal = useDealsModal();

  const {
    products,
    isLoading,
    pagination,
    sortingMethods,
    buildCatalogLink,
    clearAll,
    productFilters,
    catalogQuery,
    filtersDescriptor,
    setFilter,
    clearField,
    setSortingMethod,
    areFiltersTouched,
    allCategories,
    changeCatalogCategory,
    clearTempFilters,
    setTempField,
    clearTempField,
    applyTempFilters,
    snapTempFilters,
    resetTempField,
    releaseTempFilters,
    isDeals,
    getCategoryName,
  } = useCatalog(outerFilters);
  const { sortingMethod, categoryId } = catalogQuery;

  const compilationId = 'compilationId' in catalogQuery ? catalogQuery.compilationId : '';

  const preparedSortingMethods = useMemo(
    () => (sortingMethods || []).map(({ name, id }) => ({ value: id, label: name })),
    [sortingMethods],
  );

  const [isFiltersOpened, setFiltersOpened] = useState(false);

  const toggleFilters = useCallback(
    () =>
      setFiltersOpened((v) => {
        if (!v) {
          snapTempFilters();
        } else {
          releaseTempFilters();
        }
        return !v;
      }),
    [releaseTempFilters, snapTempFilters],
  );

  const topFilter = useMemo(() => {
    return [
      lessThan.xl && filtersDescriptor[FilterKey.QuickFilter],
      filtersDescriptor[catalogQuery.categoryId === -1 ? FilterKey.Category : FilterKey.SubCategory],
    ].filter(Boolean) as ProductFilterTag[];
  }, [catalogQuery.categoryId, filtersDescriptor, lessThan.xl]);

  const categoryName = getCategoryName(categoryId);

  const [value, setValue] = usePersistState('local', 'expanded', {});
  const getStateHandler = useCallback(
    ({ name, defaultExpanded }: { name: string; defaultExpanded: boolean }) => {
      const _value: Record<string, boolean> = { ...(value || {}) };
      return {
        onToggle: (v?: boolean) => {
          _value[name] = !!v;
          setValue(_value);
        },
        expanded: _value[name] ?? defaultExpanded,
      };
    },
    [setValue, value],
  );

  const breadcrumbs = useMemo(() => {
    const START_POINT = {
      text: getBreadcrumbTitle(Routes.Catalog),
      href: buildCatalogLink({ filters: null, subPath: null, searchTerm: null, page: null }),
    };
    const COMPILATION_END_POINT = { text: compilationTitle };
    const CATEGORY_END_POINT = { text: categoryName };
    const DISCOUNT_POINT = {
      text: intl.t('pages.discounts.breadcrumbTitle', undefined, 'Discounts'),
      href: Routes.Discounts,
    };

    switch (compilationType) {
      case CompilationType.CAROUSEL:
        return [START_POINT, COMPILATION_END_POINT];
      case CompilationType.DISCOUNT:
        return [START_POINT, DISCOUNT_POINT, COMPILATION_END_POINT];
      default:
        return categoryName ? [START_POINT, CATEGORY_END_POINT] : [START_POINT];
    }
  }, [buildCatalogLink, categoryName, compilationTitle, compilationType, intl]);

  const buildLink = useCallback(
    (page: number) =>
      buildCatalogLink({
        page: String(page),
      }),
    [buildCatalogLink],
  );

  const pagesCount = Math.ceil(pagination.total / pagination.pageSize);

  const handlePageChange = useCallback(() => {
    scroll({ top: 0 });
  }, [scroll]);

  const Placeholders = useMemo(() => Array(pagination.pageSize).fill(<ProductCardSkeleton />), [pagination.pageSize]);

  const inactivityReason =
    isCompilationPage && compilationType === CompilationType.DISCOUNT && pageHeader?.inactivityReason;

  if (inactivityReason && inactivityReason !== InactivityReason.ANOTHER_TIME) {
    return <InactiveDiscount reason={inactivityReason} />;
  }

  return (
    <div className={cx(isLoading && styles._pending)}>
      {pageHeader?.title ? (
        <PageHeader
          {...pageHeader}
          button={pageHeader.button}
          breadcrumbs={breadcrumbs}
          useBackHistory
        />
      ) : (
        <Box
          mt={{
            mobile: 'sm',
          }}
        >
          <PathBasedBreadcrumbs breadcrumbs={breadcrumbs} />
        </Box>
      )}
      <Toolbar
        filters={productFilters}
        onClickFilter={toggleFilters}
        topFilter={topFilter}
        button={
          isDeals ? (
            <Button
              ariaLabel="All Deals"
              icon={DiscountLabelIcon}
              onClick={() => dealsModal.open()}
              color="light"
              size="sm"
            >
              All Deals
            </Button>
          ) : undefined
        }
        onClearAll={clearAll}
        categoryMenu={
          !isCompilationPage && (
            <CategoryMenu
              categoryId={catalogQuery.categoryId}
              categories={allCategories}
              onChange={changeCatalogCategory}
            />
          )
        }
        pt={pageHeader?.title ? 'sm' : undefined}
        sortOptions={preparedSortingMethods}
        setFilter={setFilter}
        clearFilter={clearField}
        sortingMethod={sortingMethod}
        setSortingMethod={setSortingMethod}
        areFiltersTouched={areFiltersTouched}
      />
      <div className={styles.container}>
        <SidebarLayout
          sidebar={
            desktop ? (
              <SidebarFilters
                filters={productFilters}
                filterStateHandler={getStateHandler}
                setFilter={setFilter}
                clearFilter={clearField}
              />
            ) : null
          }
        >
          {products.length > 0 ? (
            <>
              {renderGrid(products, (product, index) => (
                <ProductCardConnected
                  layout="vertical"
                  className={styles.productCard}
                  product={product}
                  lazy={index >= MAX_NOT_LAZY_ITEMS}
                  width={340}
                  index={index}
                  analyticalItemListId={
                    isCompilationPage
                      ? compilationType === CompilationType.DISCOUNT
                        ? `promotion_${compilationId}`
                        : `carousel_${compilationId}`
                      : 'catalog'
                  }
                  analyticalItemListName={isCompilationPage ? compilationTitle || '' : 'catalog'}
                />
              ))}
              {pagesCount > 1 && (
                <Pagination
                  className={styles.pagination}
                  currentPage={pagination.page}
                  pageSize={pagination.pageSize}
                  total={pagination.total}
                  buildHref={buildLink}
                  onChange={handlePageChange}
                />
              )}
            </>
          ) : isLoading ? (
            renderGrid(Placeholders)
          ) : (
            <CatalogFormEmptyState onReset={clearAll} />
          )}
        </SidebarLayout>
        <ScrollToTop />
      </div>
      {!desktop && (
        <MobileFilters
          isOpened={isFiltersOpened}
          filters={productFilters}
          filtersDescriptor={filtersDescriptor}
          onResetField={resetTempField}
          total={pagination.total}
          isLoading={isLoading}
          onClose={toggleFilters}
          onApply={applyTempFilters}
          onClearAll={clearTempFilters}
          onClearField={clearTempField}
          onSetField={setTempField}
          areFiltersTouched={areFiltersTouched}
        />
      )}
      {dealsModal.modal}
    </div>
  );
};

export { Catalog };
export default Catalog;
