import { CloseIcon } from '@swe/shared/ui-kit/components/icon';
import { SectionProps } from '@swe/shared/ui-kit/components/section';

import {
  ProductFilterAny,
  ProductFilterTag,
  ProductFilterTagMultiple,
  ProductFilterType,
  ProductFilterUnit,
} from 'entities/product/filter';

const getFilterValueName = (filter: ProductFilterTag | ProductFilterTagMultiple, value: any) => {
  return filter.options.find(({ id }) => id === value)?.name ?? value;
};

const getFilterValue = (filter: ProductFilterAny, showAll = false, onlyCount = false, separator = ', ') => {
  switch (filter.type) {
    case ProductFilterType.RANGE:
      switch (filter.range.unitAbbr) {
        case ProductFilterUnit.DOLLAR:
          return `${filter.range.unitAbbr}${filter.value[0]} - ${filter.range.unitAbbr}${filter.value[1]}`;
        case ProductFilterUnit.PERCENT:
          return `${filter.value[0]}${filter.range.unitAbbr} - ${filter.value[1]}${filter.range.unitAbbr}`;
        default:
          return `${filter.value[0]} - ${filter.value[1]}`;
      }
    case ProductFilterType.MULTIPLE:
      if (onlyCount) return filter.value.length;
      if (!showAll && filter.value.length > 1 && filter.uiLabel) return filter.value.length;

      return filter.value.map((v) => getFilterValueName(filter, v)).join(separator);
    case ProductFilterType.SINGLE:
      return getFilterValueName(filter, filter.value);
    default:
      return null;
  }
};

const prepareFilterHeadingProps = (
  filter: ProductFilterAny,
  onReset?: (key: string) => void,
): SectionProps['heading'] => {
  if (!filter.uiLabel) {
    return undefined;
  }
  const handler =
    onReset &&
    ((e: React.SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onReset(filter.key);
    });

  const badgeText = getFilterValue(filter, false, true);

  return {
    size: 'sm',
    children: filter.uiLabel,
    ...(filter.isTouched
      ? {
          badge: {
            children: badgeText,
          },
          button: handler && {
            icon: CloseIcon,
            variant: 'link',
            color: 'danger',
            children: 'Clear',
            onClick: handler,
          },
        }
      : {}),
  };
};

export { prepareFilterHeadingProps, getFilterValue, getFilterValueName };
