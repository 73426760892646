import { Chips } from '@swe/shared/ui-kit/components/chips';
import InlineScrollableWrapper from '@swe/shared/ui-kit/components/form/wrapper/inline-scrollable';

import { ReactNode } from 'react';

import styles from './styles.module.scss';

import { ChipsSelected } from 'domains/catalog/components/selected-filters/chips';
import { getFilterValueName } from 'domains/catalog/helpers';
import { ProductFilters, ProductFilterType } from 'entities/product/filter';

type SelectedFiltersProps = {
  filters: ProductFilters;
  setFilter: (key: string, value: any) => void;
  clearFilter: (key: string) => void;
  start?: ReactNode;
};

const SelectedFilters = ({ filters, setFilter, clearFilter, start }: SelectedFiltersProps) => {
  const touchedFilters = filters.filter((filter) => filter.isTouched);

  return (
    <InlineScrollableWrapper controls="all">
      <div className={styles.tags}>
        {start}
        {touchedFilters.length > 0 ? (
          touchedFilters.map((filter, idx) => {
            if (filter.expandedByDefault && filter.type === ProductFilterType.MULTIPLE) {
              return filter.value.map((val: EntityID) => (
                <Chips
                  key={`${filter.key}_${val}`}
                  onClose={() =>
                    setFilter(
                      filter.key,
                      filter.value.filter((id: EntityID) => id !== val),
                    )
                  }
                >
                  {getFilterValueName(filter, val)}
                </Chips>
              ));
            }

            return (
              <ChipsSelected
                key={filter.key}
                filter={filter}
                idx={idx}
                resetField={clearFilter}
                setValue={setFilter}
              />
            );
          })
        ) : (
          <Chips className={styles.empty}>Empty</Chips>
        )}
      </div>
    </InlineScrollableWrapper>
  );
};

export { SelectedFilters };
export default SelectedFilters;
