import { useBreakpoint } from '@swe/shared/tools/media';
import Box, { BoxProps } from '@swe/shared/ui-kit/components/box';
import Button from '@swe/shared/ui-kit/components/button';
import { CheckboxGroup, Option } from '@swe/shared/ui-kit/components/form/checkbox-group';
import { Select, SelectOption } from '@swe/shared/ui-kit/components/form/select';
import Grid from '@swe/shared/ui-kit/components/grid';
import { FilterIcon, SortIcon } from '@swe/shared/ui-kit/components/icon';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import { SidebarLayout } from '@swe/shared/ui-kit/layouts/sidebar';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { ReactNode, useCallback } from 'react';

import { SelectedFilters } from 'domains/catalog/components/selected-filters';
import { CatalogSortingMethodId } from 'domains/catalog/constants';
import { ProductFilterOption, ProductFilters, ProductFilterTag } from 'entities/product/filter';

type TFOption = {
  key: string;
  name: string;
  value: ProductFilterOption['id'];
};
type ToolbarFilterOption = Option<TFOption>;

const mapFilterToOptions = (filter: ProductFilterTag): ToolbarFilterOption[] =>
  filter.options.map((option) => ({
    name: option.name,
    label: option.name,
    value: {
      key: filter.key,
      name: option.name,
      value: option.id,
    },
    disabled: option.disabled ?? false,
  }));

const mapToolbarFilters = (filters: ToolbarProps['topFilter']) => (filters ?? []).map(mapFilterToOptions).flat();

type ToolbarProps = ComponentHasClassName & {
  filters: ProductFilters;
  topFilter?: ProductFilterTag[];
  onClickFilter?: () => void;
  categoryMenu: ReactNode;
  onClearAll?: () => void;
  pt?: BoxProps<any>['pt'];
  sortOptions: SelectOption<number>[];
  setFilter: (key: string, value: any) => void;
  clearFilter: (key: string) => void;
  areFiltersTouched: boolean;
  setSortingMethod: (id: CatalogSortingMethodId) => void;
  sortingMethod: CatalogSortingMethodId;
  button?: ReactNode;
};

const Toolbar = ({
  className,
  filters,
  onClickFilter,
  categoryMenu,
  topFilter,
  onClearAll,
  pt,
  sortOptions,
  setFilter,
  areFiltersTouched,
  clearFilter,
  sortingMethod,
  setSortingMethod,
  button,
}: ToolbarProps) => {
  const { desktop } = useBreakpoint();

  const handleToolbarTagChange = useCallback(
    (options: TFOption[]) => {
      const [option] = options;
      setFilter(option.key, [option.value]);
    },
    [setFilter],
  );

  const sidebar = (
    <SectionHeading
      level={2}
      size="lg"
      link={areFiltersTouched ? { children: 'Clear All', onClick: onClearAll } : undefined}
    >
      Filters
    </SectionHeading>
  );

  return (
    <Box
      pt={pt}
      mb="md"
      className={className}
    >
      <Grid.Row
        hAlign="end"
        vAlign="center"
        spacing="xs"
      >
        {categoryMenu && (
          <Grid.Cell
            cols={{
              xs: 12,
              sm: 12,
              md: 'auto',
            }}
          >
            {categoryMenu}
          </Grid.Cell>
        )}
        <Grid.Cell
          cols={{
            sm: 'auto',
            md: 'content',
            lg: 'content',
          }}
        >
          <Select
            icon={SortIcon}
            name="sortingMethodId"
            label={false}
            options={sortOptions}
            value={sortingMethod}
            onChange={setSortingMethod}
            size="md"
            staticNote={false}
          />
        </Grid.Cell>
        <Grid.Cell
          cols="content"
          visible={{ xl: false, sm: true }}
          order={{ xs: 3, sm: 4 }}
        >
          <Button
            icon={FilterIcon}
            color="ghost"
            onClick={onClickFilter}
            size="sm"
            indicator={areFiltersTouched ? 'primary' : undefined}
            name="filters"
          >
            Filters
          </Button>
        </Grid.Cell>
        <Grid.Cell
          cols={12}
          order={8}
        >
          <SidebarLayout
            vAlign="center"
            sidebar={desktop ? sidebar : undefined}
          >
            {!areFiltersTouched && topFilter ? (
              <CheckboxGroup<TFOption>
                start={button}
                name="toolbar-tags"
                variant="tag"
                value={[]}
                options={mapToolbarFilters(topFilter)}
                inline
                onChange={handleToolbarTagChange}
              />
            ) : (
              <SelectedFilters
                filters={filters}
                start={button}
                setFilter={setFilter}
                clearFilter={clearFilter}
              />
            )}
          </SidebarLayout>
        </Grid.Cell>
      </Grid.Row>
    </Box>
  );
};

export type { ToolbarProps };
export { Toolbar };
export default Toolbar;
